var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DefaultModal',{ref:"moveModal",attrs:{"title":_vm.$t('move_confirm_title'),"submit-button-text":"move","loading":_vm.modalLoading,"saveButtonDisabled":_vm.modalSaveButtonDisabled,"alert":_vm.modalAlert},on:{"submit":_vm.callMoveMethod,"modal-closed":function($event){return _vm.resetConfirmModal()}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('v-col',{staticClass:"text-body-1"},[_c('v-row',[_c('p',{staticClass:"pre-line"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("From:")]),_vm._v(" "+_vm._s(_vm.destination.from))])]),_c('v-row',[_c('p',[_c('span',{staticClass:"font-weight-bold"},[_vm._v("To:")]),_vm._v(" "+_vm._s(_vm.destination.to))])]),(_vm.modalAlert)?_c('v-row',{staticClass:"mt-4"},[_c('v-alert',{attrs:{"dense":"","outlined":"","type":_vm.modalAlert.type}},[_vm._v(" "+_vm._s(_vm.modalAlert.message)+" ")])],1):_vm._e()],1)]},proxy:true}])}),(_vm.items)?_c('v-breadcrumbs',{staticClass:"breadcrumbs-custom",attrs:{"items":_vm.formattedBreadcrumbs},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('v-icon',[_vm._v("mdi-chevron-right")])]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"open-delay":"1000","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-breadcrumbs-item',_vm._g(_vm._b({ref:item.ref,class:{ 'drag-over': _vm.canDragOver(item) },style:(_vm.maxWidthItem ? _vm.maxWidthItemClass : false),attrs:{"to":item.to,"href":item.href,"link":item.link,"disabled":item.disabled,"exact":item.exact},on:{"click":function($event){return _vm.onBreadcrumbItemClick(item)},"drop":_vm.onDrop,"dragover":function($event){return _vm.dragOver($event, item)},"dragenter":function($event){return _vm.dragEnter($event, item)},"dragleave":function($event){return _vm.dragLeave()},"dragend":function($event){return _vm.dragEnd()}}},'v-breadcrumbs-item',attrs,false),on),[(item.prependIcon)?_c('v-icon',{attrs:{"color":item.iconColor || 'defaultIconColor'}},[_vm._v(_vm._s(item.prependIcon))]):_vm._e(),_vm._v(" "+_vm._s(item.text)+" ")],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.tooltipText ? item.tooltipText : item.text))])])]}}],null,false,3636956862)}):_vm._e(),_c('v-menu',{attrs:{"position-x":_vm.x,"position-y":_vm.y,"absolute":"","offset-y":"","nudge-bottom":"8","nudge-left":"8"},model:{value:(_vm.showMenu),callback:function ($$v) {_vm.showMenu=$$v},expression:"showMenu"}},[_c('v-list',{staticClass:"menu-custom"},_vm._l((_vm.menuItems),function(item,index){return _c('v-list-item',{key:index,attrs:{"link":"","href":item.href,"to":item.to}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"defaultIconColor"}},[_vm._v("mdi-folder")])],1),_c('v-tooltip',{attrs:{"open-delay":"1000","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item-content',[_c('v-list-item-title',_vm._g(_vm._b({},'v-list-item-title',attrs,false),Object.assign({}, tooltip)),[_vm._v(_vm._s(_vm.$t(item.text)))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.text))])])],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }